import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useFlagshipBattleResult from '../../../../hooks/useFlagshipBattleResult';
import useMain from '../../../../hooks/useMain';
import { numberWithCommas } from '../../../../utils/helpers';
import DigitalWatch from '../../../common/digitalWatch';
import Image from '../../../common/image';
import { TextCard } from '../../ingredients/cards';

const Contest = () => {
	const { initData } = useMain();
	const { end, start } = initData.contests.flagshipBattle;
	const [isFinished, setIsFinished] = useState(Date.now() >= end);
	const [result] = useFlagshipBattleResult();
	if (Date.now() < start || !result) return null;
	const { team_1, team_2 } = result;

	const score = parseInt(((team_1.amount * 100) / (team_1.amount + team_2.amount)) * 100) / 100;

	const isTeam_1_winner = team_1.amount >= team_2.amount;
	const isTeam_2_winner = team_2.amount >= team_1.amount;

	return (
		<div className='row'>
			<div className='col-sm-12'>
				<Link to='/dashboard/bonus/flagshipBattle'>
					<TextCard
						className='flagship'
						text={
							<div className='row gy-5 text-white align-items-center'>
								<div className='col-lg-4 text-center'>
									<Image
										className='w-100 d-none d-lg-inline-flex'
										fileName='flagship/vsKit.png'
									/>
									<Image
										className='w-100 d-inline-flex d-lg-none'
										fileName='flagship/vsKitM.png'
									/>
								</div>

								<div className='col-lg-4 text-center'>
									{isFinished ? (
										<>
											<h4>Tournament is finished!</h4>
											<h5>check the result</h5>
										</>
									) : (
										<div>
											<div className='fs-4 mb-1'>Remaining Time: </div>
											<DigitalWatch
												className='d-inline-block'
												onComplete={() => setIsFinished(true)}
												date={end}
											/>
										</div>
									)}
								</div>
								<div className='col-lg-4 text-center'>
									<div className='contestResult'>
										<div className='d-flex justify-content-between align-items-center'>
											<div className='fs-5 ms-4'>
												<div className='position-relative'>
													<Image
														height='50px'
														fileName={`flagship/${initData.contests.flagshipBattle.team_1.id}.svg`}
													/>
													{isTeam_1_winner && (
														<Image
															className='position-absolute'
															height='25px'
															fileName='flagship/cup.svg'
														/>
													)}
												</div>
												<div>
													{numberWithCommas(result.team_1.amount)} USD
												</div>
											</div>
											<div className='fs-5 me-4'>
												<div className='position-relative'>
													<Image
														height='50px'
														fileName={`flagship/${initData.contests.flagshipBattle.team_2.id}.svg`}
													/>
													{isTeam_2_winner && (
														<Image
															className='position-absolute'
															height='25px'
															fileName='flagship/cup.svg'
														/>
													)}
												</div>
												<div>
													{numberWithCommas(result.team_2.amount)} USD
												</div>
											</div>
										</div>
										<div
											className='progress'
											role='progressbar'
											aria-valuenow={score}
											aria-valuemin='0'
											aria-valuemax='100'>
											<div
												className='progress-bar'
												style={{ width: `${score}%` }}></div>{' '}
										</div>
									</div>
									<Image
										className='w-50 d-inline-flex d-lg-none my-5'
										fileName='flagship/logo.svg'
									/>
								</div>
							</div>
						}
					/>
				</Link>
			</div>
		</div>
	);
};

export default Contest;
