import React, { useState } from 'react';
import { RiseLoader } from 'react-spinners';
import config from '../../../../config';
import styles from '../../../../css/dashboard/ingredients.module.css';
import useFlagshipBattleResult from '../../../../hooks/useFlagshipBattleResult';
import useMain from '../../../../hooks/useMain';
import getCSSVars from '../../../../utils/getCSSVars';
import { numberWithCommas } from '../../../../utils/helpers';
import { paginate } from '../../../../utils/paginate';
import DigitalWatch from '../../../common/digitalWatch';
import Image from '../../../common/image';
import Pagination from '../../../common/pagination';
import FlagshipBattleTable from '../../ingredients/flagshipBattleTable';

function Bonus() {
	const { initData } = useMain();
	const { end, name, amount, team_1, team_2 } = initData.contests.flagshipBattle;
	const [timeIsFinished, setTimeIsFinished] = useState(Date.now() >= end);
	const [result] = useFlagshipBattleResult();
	const [team_1_currentPage, team_1_setCurrentPage] = useState(1);
	const [team_2_currentPage, team_2_setCurrentPage] = useState(1);

	if (!result)
		return (
			<div className='h-50 d-flex justify-content-center align-items-center my-5'>
				<RiseLoader
					speedMultiplier={0.8}
					loading={true}
					size={20}
					sizeUnit='px'
					color={getCSSVars('my-purple')}
				/>
			</div>
		);

	const team_1_normalizedData = result ? [...result.team_1.users] : [];
	if (result && team_1_normalizedData.length < amount) {
		for (let i = team_1_normalizedData.length; i < amount; i++) {
			team_1_normalizedData.push({
				email: '',
				uid: null,
				date: null,
			});
		}
	}
	for (const i in team_1_normalizedData) team_1_normalizedData[i].no = Number(i) + 1;
	const team_1_data = paginate(team_1_normalizedData, team_1_currentPage, config.tablePageSize);

	const team_2_normalizedData = result ? [...result.team_2.users] : [];
	if (result && team_2_normalizedData.length < amount) {
		for (let i = team_2_normalizedData.length; i < amount; i++) {
			team_2_normalizedData.push({
				email: '',
				uid: null,
				date: null,
			});
		}
	}
	for (const i in team_2_normalizedData) team_2_normalizedData[i].no = Number(i) + 1;
	const team_2_data = paginate(team_2_normalizedData, team_2_currentPage, config.tablePageSize);

	const isTeam_1_winner = result.team_1.amount >= result.team_2.amount;
	const isTeam_2_winner = result.team_2.amount >= result.team_1.amount;

	return (
		<div className='mb-4 card myCards'>
			<div className='card-body'>
				<div className='my-4'>
					{timeIsFinished ? (
						<h2>Time Is Finished</h2>
					) : (
						<div className='text-center'>
							<DigitalWatch
								className='d-inline-block'
								onComplete={() => setTimeIsFinished(true)}
								date={end}
							/>
						</div>
					)}
				</div>

				<div className='row g-5'>
					<div
						className={`col-md-6 order-md-0 col-12 order-${
							isTeam_1_winner ? '0' : '1'
						}`}>
						<div className='row gy-4 align-items-center'>
							<div className='col-12 text-center'>
								<Image
									height='80px'
									fileName={`flagship/${team_1.id}.svg`}
								/>
								<div className='fs-4'>{team_1.name}</div>
							</div>
							<div className='col-6 text-center'>
								<div className='fs-5'>
									Total Amount: {numberWithCommas(result.team_1.amount)} USD
								</div>
							</div>
							<div className='col-6 text-center'>
								<div className='d-flex justify-content-center align-items-center'>
									<div className='fs-2 me-2'>
										{result.team_1.users.length} / {amount}
									</div>
									<Image
										height='50px'
										fileName='flagship/ticket.svg'
									/>
								</div>
								{result.team_1.users.length >= amount && (
									<div className='mt-3'>Tickets Are Finished</div>
								)}
							</div>
							<div className='col-12'>
								<FlagshipBattleTable
									data={team_1_data}
									title={name}
								/>
								<Pagination
									customClass={`mt-1 ${styles.myPagination}`}
									activeClass={styles.myActive}
									totalCount={team_1_normalizedData.length}
									pageSize={config.tablePageSize}
									currentPage={team_1_currentPage}
									onPageChange={team_1_setCurrentPage}
								/>
							</div>
						</div>
					</div>

					<div
						className={`col-md-6 order-md-0 col-12 order-${
							isTeam_2_winner ? '0' : '1'
						}`}>
						<div className='row gy-4 align-items-center'>
							<div className='col-12 text-center'>
								<Image
									height='80px'
									fileName={`flagship/${team_2.id}.svg`}
								/>
								<div className='fs-4'>{team_2.name}</div>
							</div>
							<div className='col-6 text-center'>
								<div className='fs-5'>
									Total Amount: {numberWithCommas(result.team_2.amount)} USD
								</div>
							</div>
							<div className='col-6 text-center'>
								<div className='d-flex justify-content-center align-items-center'>
									<div className='fs-2 me-2'>
										{result.team_2.users.length} / {amount}
									</div>
									<Image
										height='50px'
										fileName='flagship/ticket.svg'
									/>
								</div>
								{result.team_2.users.length >= amount && (
									<div className='mt-3'>Tickets Are Finished</div>
								)}
							</div>
							<div className='col-12'>
								<FlagshipBattleTable
									data={team_2_data}
									title={name}
								/>
								<Pagination
									customClass={`mt-1 ${styles.myPagination}`}
									activeClass={styles.myActive}
									totalCount={team_2_normalizedData.length}
									pageSize={config.tablePageSize}
									currentPage={team_2_currentPage}
									onPageChange={team_2_setCurrentPage}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Bonus;
