import React, { useState } from 'react';
import Countdown from 'react-countdown';
import useMain from '../../../../hooks/useMain';
import Image from '../../../common/image';
import { TextCard } from '../../ingredients/cards';

const Festivals = () => {
	const { initData } = useMain();
	const { saleEvents } = initData;

	const firstDay = saleEvents[0];
	const lastDay = saleEvents[saleEvents.length - 1];

	const [isFinished, setIsFinished] = useState(Date.now() >= lastDay.endTime);

	if (Date.now() < firstDay.startTime) return null;
	return (
		<div className='row'>
			<div className='col-sm-12'>
				<TextCard
					className='gala'
					text={
						<div className='row align-items-center text-light'>
							<div className='col-md-6 col-12 text-center'>
								<Image
									className='w-100 d-none d-md-block'
									fileName='festival/gala/img.png'
								/>
								<Image
									className='w-100 d-md-none'
									fileName='festival/gala/imgMob.png'
								/>
							</div>
							<div className='col-md-6 col-12 text-center'>
								<Image
									className='w-75 my-3'
									fileName='festival/gala/title.png'
								/>
								<div>
									Celebrate the 2nd Anniversary of MMP WORLD with Exciting
									Festival Bonuces.
								</div>
								<div className='row'>
									<div className='col-md-6'>
										<Image
											className='w-50 col-12 my-3'
											fileName='festival/gala/timeline.svg'
										/>
									</div>
									<div className='col-md-5 col-12'>
										<div className='mt-3'>
											{isFinished ? (
												<h4>Event is ended.</h4>
											) : (
												<div>
													<Countdown
														className='fs-2'
														onComplete={() => setIsFinished(true)}
														date={lastDay.endTime}
													/>
												</div>
											)}
										</div>
										<div className='position-relative'>
											<Image
												className='w-75 my-3 blur'
												fileName='festival/gala/dates.svg'
											/>
											<Image
												className='w-100 position-absolute top-50 start-50 translate-middle'
												fileName='festival/gala/extended.png'
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					}
				/>
			</div>
		</div>
	);
};

export default Festivals;
