import React from 'react';
import { Link } from 'react-router-dom';
import useElemSize from '../../hooks/useElemSize';
import Image from '../common/image';
import styles from '../../css/dashboard/header.module.css';

const Header = ({ onTogglerClicked }) => {
	const [ref] = useElemSize('header');

	return (
		<header
			ref={ref}
			className={`navbar ${styles.navbar} navbar-light flex-md-nowrap p-0 d-block d-md-none`}>
			<Link className={`navbar-brand col-md-3 col-lg-2 me-0 px-3`} to='/'>
				<Image
					className='d-inline-block align-top my-1'
					fileName={`logo.png`}
					height='50'
				/>
			</Link>
			<button
				onClick={onTogglerClicked}
				className={`navbar-toggler ${styles['navbar-toggler']} position-absolute d-md-none collapsed`}
				type='button'>
				<span className='navbar-toggler-icon'></span>
			</button>
		</header>
	);
};

export default Header;
